.image_select {
  &_wrap {
    width: 100%;
    height: 120px;
    border-radius: 8px;
    padding: 7px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    margin-bottom: 32px;
    &:hover {
      border: 1px solid #00ACC0;
    }
    &__open {
      border: 1px solid #00ACC0;
    }
    &__closed {
      border: 1px solid #5C5C6E;
    }
    &.disabled {
      border: 1px solid #5C5C6E;
      cursor: not-allowed;
      background: #F2F2F3;
    }
  }
  &_picture {
    width: 204px;
    height: 104px;
    margin-right: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    &__not_selected {
      background: #F2F2F3;
    }
    &.disabled:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: #dadadaa8;
    }
  }
  &_text {
    width: calc(100% - 254px);
    margin-right: 8px;
    &_label {
      width: calc(100% - 254px);
      pointer-events: none;
      transition: all 0.2s ease 0s;
      color: #9696A2;
      &__selected {
        width: auto;
        position: absolute;
        font-size: 12px;
        top: -12px;
        left: 16px;
        background: white;
        color: #9696A2;
      }
    }
  }
  &_options_wrap {
    margin-top: 10px;
    height: 100%;
    overflow-y: scroll;
  }
}
