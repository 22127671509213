.draggable_form_row {
  &_bottom {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    margin-right: 31px;
  }
}

.draggable_delete_button {
  height: 40px;
  display: flex;
  align-items: center;

  &_icon {
    fill: #5C5C6E;
    &:hover {
      fill: #DA1B5E;
    }
  }
}
