.cellTypeActive
  &:first-child
    .react-select__control
      border-radius: 8px 0 0 8px !important

.footnoteContainer
  position: relative
  padding-right: 24px

  label
    position: absolute,
    font-size: 16px,
    color: #9696a2,
    left: 38px,
    top: 10px,
    padding: 2px,
    background-color: white,
    transition-duration: 0.3s
    pointer-events: none

  textarea:focus + label,
  textarea:active + label,
  label.active
    font-size: 12px,
    left: 38px,
    top: -9px,
