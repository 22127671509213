.audio_player {
  &_panel {
    height: 24px;
    &:not(.disabled):hover .audio_player_btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_file_box {
    height: 24px;
    width: 180px;
    justify-content: flex-end;
    &:hover .audio_player_icon {
      color: #831038;
    }
    &:hover .audio_player_file_name {
      color: #831038;
    }
  }

  &_icon {
    height: 24px;
    width: 16px;
    margin-right: 8px;
    color: #5C5C6E;
  }

  &_file_name {
    font-size: 12px;
    line-height: 15px;
  }

  &_btn_wrap {
    width: 54px;
    height: 24px;
  }

  &_btn {
    width: 24px;
    height: 24px;
    min-width: 24px;
    padding: 3px;
    display: none;
    &__visible {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__play {
      color: #E9769E;
      border-color: #E9769E;
    }
  }

  &_wrap {
    width: 281px;
    height: 90px;
    background: #404055;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 24px 13px 13px;
    position: relative;
  }

  &_close_btn {
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    &_icon {
      width: 7px;
      height: 7px;
    }
  }

  &_time_block {
    margin-bottom: 12px;
  }

  &_time_text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #CFCFD4;
    width: 38px;
  }

  &_time_slider {
    width: 156px;
    margin: 2px 5px;
    padding-block: 5px;
  }

  &_controls {
    &_block {
      height: 24px;
      width: 100px;
    }
    &_play_btn {
      height: 24px;
      width: 24px;
    }
  }
}