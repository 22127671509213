.element_flow_box {
  display: flex;
  height: 40px;
  width: 97px;
  overflow: hidden;
  cursor: pointer;
  padding: 8px 5px;
  margin-bottom: 8px;
  align-items: center;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  &__selected {
    border: 1px solid #0092A3;
    border-radius: 8px;
  }
}
