.wrapper {
  height: calc(100vh - 240px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner {
  text-align: center;
}

.text {
  display: block;
  font-size: 24px;
  font-family: Arial, sans-serif;
  line-height: 27px;
  margin-top: 30px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ul, ol {
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      text-align: left;
    }
  }
}

.subtext {
  font-family: Arial, sans-serif;
  font-size: 20px;
  line-height: 23px;
}
