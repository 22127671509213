.counterInputContainer {
  position: relative;

  .currencyContainer {
    display: none;
    position: absolute;
    z-index: 5;
    right: 24px;
    top: 51%;
    transform: translateY(-50%);
    color: #002a5c;
  }

  &.hasCurrency {
    .currencyContainer {
      display: block;
    }

    &.longCurrency {
      input {
        padding-right: 40px !important;
      }
    }
  }
}