.preview {
  &_cell {
    height: 70px;
    width: 135px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    &_hover {
      display: none;
      &_icon {
        margin-right: 8px;
      }
    }

    &:hover .preview_cell_hover {
      height: 75px;
      width: 137px;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left:0;
      right: 0;
      cursor: pointer;
    }
  }

  &_modal_image {
    width: 100%;
  }
}