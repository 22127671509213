@import '../../mixins';

.slider-container {
  display: flex;

  @include for-phone-only {
    flex-direction: column;
    align-items: center;
    row-gap: 32px;
    margin-bottom: 20px;
  }
}

.slick-track {
  display: flex !important;
}


.slick-slide {
  height: inherit !important;

  & > div {
    display: flex;
    min-height: 100%;

    & > div {
      display: flex !important;
    }
  }
}

.slick-next {
  right: 0;
}

.classOfCenterModeCarousel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkboxStylesForDisabledCarouselSlider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.disabledCarouselSlider {
  opacity: 0.2;
  pointer-events: none;
}
