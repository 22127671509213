@mixin for-phone-only {
  @container (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @container (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @container (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @container (min-width: 1200px) { @content; }
}


@mixin for-tablet-down {
  @container (max-width: 900px) { @content; }
}

@mixin for-desktop-down {
  @container (max-width: 1200px) { @content; }
}
