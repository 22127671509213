.icon_btn {
  height: 16px;
  width: 16px;
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
}

.filter {
  &_btn {
    margin-left: 16px;
    margin-top: 2px;
  }
}

.row-light {
  background-color: rgba(255, 255, 255, 0.7);
}

.row-dark {
  background-color: #F2F7F9;
}

.table_btn {
  width: 168px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__lg {
    width: 235px;
  }

  &__tab {
    margin-bottom: 0;
  }
}

.inner_btn_icon {
  margin-right: 7px;
}

.status {
  height: 24px;
  width: fit-content;
  background: #858899;
  border-radius: 8px;
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__active {
    background: #008F73;
  }

  &__pending {
    background: #FC9155;
  }

  &_text {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: #FFFFFF;
  }
}
