.media{
  &_wrap {
    height: 112px;
    width: 193px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    z-index: 11;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &__loading {
      background-color: #F2F2F3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover .media_hidden_box {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      top: 0;
      bottom: 0;
      left:0;
      right: 0;
      cursor: default;
    }
  }

  &_hidden_box {
    display: none;
    &_icon {
      margin-right: 8px;
    }
  }

}