.container {
  flex: 1;
  container-type: inline-size;

  & > *:first-child {
    padding-top: 32px !important;
    margin-top: 0 !important;
  }
}

