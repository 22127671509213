.switch {
  border-radius: 8px;
  border: 1px solid #191919;
  height: 24px;
  display: flex;
  padding: 2px;
  box-sizing: border-box;
  cursor: pointer;
  &_disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.items {
  display: flex;
  align-items: center;
  position: relative;
}

.item {
  z-index: 1;
  text-align: center;
  line-height: 14px;
  &_text {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #191919;
    transition: 0.3s all;
    &_active {
      transition: 0.3s all;
      color: #fff;
    }
  }
}

.slicer {
  position: absolute;
  background: #da1b5e;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  border-radius: 6px;
  transition: 0.3s transform;
  &_active {
    transition: 0.3s transform;
    transform: translateX(-100%);
  }
}
