.actionDropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  position: relative;

  .items {
    position: absolute;
    z-index: 100;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #5C5C6E;
    width: 180px;
    padding: 16px 0;
  }

  &.open {
    .items {
      display: block;
    }

    .chevron svg {
      transform: rotate(180deg);
    }
  }

  &.disabled {
    path {
      fill: #CFCFD4;
    }
  }

  .iconHolder {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dropdownItem {
    font-weight: 400;
    color: #191919;
    padding: 8px 16px;
    display: block;
    width: 100%;
    text-align: left;
    background-color: transparent;
    border: none;

    &.disabled {
      color: #CFCFD4;
      font-weight: 700;
    }

    &:hover {
      background-color: #F5F5F6;
    }
  }
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: #DA1B5E;
  font-weight: 700;
  cursor: pointer;

  &.disabled {
    color: #CFCFD4;
  }
}

.separator {
  background-color: #CFCFD4;
  width: 1px;
  height: 70%;
  margin-left: 18px;
  margin-right: 18px;

  &:last-child {
    display: none;
  }
}


.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tooltipContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}