.header {
  display: flex;
  align-items: center;
  min-width: 1300px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 41.2%),
    linear-gradient(287.86deg, #282828 0%, #404055 48.28%, #141414 98.62%) !important;

  &_wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &_logo_wrap {
    display: flex;
    align-items: center;
  }

  &_logo_image {
    margin-right: 16px;
  }

  &_text {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;

    &__thin {
      font-weight: 400;
      max-width: 650px !important;
    }

    &__title {
      color: #cfcfd4;
      margin-right: 8px;
    }
  }

  &_decor {
    margin: 0 16px;
  }
}
