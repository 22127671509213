.AppSpinner
  border: 1px solid #E3E3E6
  border-bottom-color: #DA1B5E
  border-radius: 50%
  display: inline-block
  box-sizing: border-box
  animation: rotation 1s linear infinite
  visibility: hidden

  &.show
    visibility: visible

@keyframes rotation
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)
