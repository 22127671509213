@import '../../../../mixins';

.wrapper {
  background: #fff;
  padding: 24px 24px 35px;
  max-width: 328px;
  width: 100%;
  border-radius: 4px;
  @include for-tablet-down {
    max-width: 100%;
  }
}