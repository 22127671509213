.element_label {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  &_text {
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
  }
}

