.finder_header {
  height: 51px;
  min-height: 51px;
  padding-inline: 24px;
  min-width: 1300px;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 41.2%), linear-gradient(287.86deg, #282828 0%, #404055 48.28%, #141414 98.62%) !important;
  position: sticky;
  top: 0;
  z-index: 100;
  &_wrap {
    display: flex;
    width: 100%;
  }

  &_back_link {
    display: flex;
    align-items: center;
    margin-right: 100px;
    &_icon {
      color: #FFFFFF;
      margin-right: 12px;
    }
  }

  &_action {
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    background: transparent;
    cursor: pointer;
    &_btn {
      height: 24px;
      width: 24px;
      padding: 3px;
    }
    &_special_btn {
      height: 24px;
      padding: 3px 16px;
      display: flex;
      align-items: center;
      &_text {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  &_actions {
    margin-right: 10px;
    &_wrap {
      width: 366px;
      display: flex;
      margin-right: 10px;
    }
  }

  &_link {
    height: 35px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    &_active {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
    }
    &_text {
      margin-left: 8px;
    }
  }

  &_links {
    //width: 872px;
    display: flex;
    justify-content: space-between;
  }

  &_finder_links_container {
    padding-right: 16px;
  }

  .preview_text {
    margin-left: 8px;
  }
  .version_text {
    margin-right: 18px;
  }

  &_publish_btn {
    height: 24px;
    width: 96px;
    padding: 5px;
    font-size: 12px;
  }
}

