.bullet_icon {
  &_box {
    height: 32px;
    width: 193px;
    color: #5C5C6E;
    display: flex;
    align-items: center;
    &:hover {
      color: #DA1B5E;
    }
    &:hover .bullet_icon_hidden_box {
      display: flex;
      cursor: pointer;
      color: #DA1B5E;
    }
  }

  &_wrap {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #CFCFD4;
    border-radius: 8px;
    margin-right: 12px;
  }
  &_image {
    max-height: 24px;
    max-width: 24px;
  }

  &_name {
    font-weight: 400;
    width: 120px;
    color: inherit;
    margin-right: 12px;
  }

  &_hidden {
    &_box {
      display: none;
    }
    &_btn {
      fill: #DA1B5E;
    }
  }
}