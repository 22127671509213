.layout_number {
  &_wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &_answer {
    margin-bottom: 12px;
    text-align: center;
  }
  &_input {
    text-align: center;
    width: 160px;
    height: 40px;
    padding: 0;
    border: 2px solid #5C5C6E;
    border-radius: 4px;
    margin: 0 16px;
    box-shadow: none;
    &:hover {
      border: 2px solid #5C5C6E;
      box-shadow: none;
    }
    &:focus {
      border: 2px solid #5C5C6E;
      box-shadow: none;
    }
    &_block {
      display: flex;
      align-items: center;
    }
  }
  &_icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
