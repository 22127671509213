.appColorPicker {

  position: relative;

  &.active {
    .chevronIcon {
      transform: rotate(180deg);
    }

    .colorPicker {
      display: block;
    }
  }

  .mainContainer {
    position: relative;
    height: 40px;
    border: 1px solid #5C5C6E;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 15px;
    justify-content: space-between;
  
    .colorBlockContainer {
  
      display: flex;
      flex-direction: row;
      align-items: center;
  
      .colorIcon {
        width: 16px;
        height: 16px;
        border-radius: 5px;
      }
  
      .colorText {
        margin-left: 8px;
        color: #191919;
        font-size: 16px;
      }
    }
  }

  .colorPicker {
    position: absolute;
    display: none;
    top: 120%;
    left: 0;
    z-index: 100;
  }
}