.option {
  &_section {
    margin-bottom: 0 !important;
  }

  &_input_prefix_icon {
    margin-right: 6px;
  }

  &_section_label {
    margin-bottom: 0 !important;
    & label {
      left: 40px
    }
  }
}

