.root
  padding: 24px 15px
  border-bottom: 1px solid #CFCFD4
  &:last-child
    border-bottom: none

.root_product
  padding: 0 15px 15px 15px
  border-bottom: 1px solid #CFCFD4
  &:last-child
    border-bottom: none

.buttonSection
  margin-bottom: 12px
  &:last-child
    margin-bottom: 0

.buttonInfo
  margin-bottom: 12px

.operationSection
  padding-left: 20px

.operationTitle
  display: block
  margin-bottom: 8px
  font-weight: 700
