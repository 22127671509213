.text_container {
  max-width: 95%;
}

.media_row {
  &_image {
    min-height: 52px;
    max-width: 100%;
    width: auto;
    &_wrap {
      height: 72px;
      padding: 5px;
      min-width: 108px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #CFCFD4;
      margin-right: 24px;
    }
  }
  &_file_name {
    font-weight: 700;
    color: #4C2FFF;
  }
}


