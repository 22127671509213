.builderPage {
  background-color: white;
  position: relative;

  .container {
    padding: 25px;
    padding-top: 0;
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
  }

  .button {
    font-size: 12px;
    line-height: 14px;
    height: 24px;
    padding: 4px 8px;

    &.primary {
      padding: 5px 25px;
    }
  }

  .buttonRow {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  
  .header {
    z-index: 100;
    background-color: white;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,0.1);
    position: sticky;
    top: 51px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .ruleName {
    margin-top: 16px;
    max-width: 680px;
  }

  .actionIconButton {
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;

    path {
      fill: #5C5C6E;
    }

    &.disabled {
      pointer-events: none;

      path {
        fill: #CFCFD4;
      }
    }
  }

  .conditions {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 16px;
    min-height: calc(100vh - 272px);
    margin-top: 16px;
    background-color: #F9F9FA;
    border: 1px solid #CFCFD4;
    border-radius: 8px;
    padding-bottom: 180px;

    .empty {
      width: 100%;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .emptyIcon {
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        position: relative;
        background: linear-gradient(to right, #E9769E, #E1497E);

        svg {
          position: relative;
          z-index: 1;
        }

        &::before, &::after {
          content: '';
          position: absolute;
          width: 50%;
          height: 100%;
          border-radius: 16px;
          top: 0;
        }

        &::before {
          background-color: #E9769E;
          left: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &::after {
          background-color: #E1497E;
          right: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      .text {
        margin-top: 24px;
        font-weight: 700;
        font-size: 16px;
        color: #191919;
      }
    }

    .condition {
      .conditionHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .generalInfo {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 18px;

          .conditionName {
            width: 336px;
            background-color: white;
          }
        }
      }

      .conditionBody {
        background-color: #FFFFFF;
        border: 1px solid #CFCFD4;
        border-radius: 8px;
        margin-top: 18px;
        padding: 10px 16px;

        &.hasError {
          border-color: #da1b5e;
        }

        .actionRow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .actionBuilder {
            display: flex;
            flex-direction: row;
          }

          .conditionControls {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 17px;
          }
        }

        .conditionalSeparator {
          height: 1px;
          background-color: #CFCFD4;
          margin: 16px 0;
        }

        .conditionalRow {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 16px;
 
        }

        .deleteIcon {
          background-color: transparent;
          border: 1px solid #5C5C6E;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 100%;
          width: 20px;
          height: 20px;
          padding: 0;
          margin-top: auto;
          margin-bottom: auto;

          &:hover {
            border-color: #E64242; 

            path {
              fill: #E64242 !important;
            }
          }
          &:focus {
            border-color: #5C5C6E;

            &:hover {
              border-color: #E64242; }
          }
        }
      }
    }
  }

  .conditionalBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}

header:global(.finder_header) {
  position: sticky;
  top: 0;
}