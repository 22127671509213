.rule_row {
  &_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_drag_icon {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
}

