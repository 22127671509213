.button_link {
  display: inline-flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  word-break: break-all;
}

