.upload_box {
  display: flex;
  align-items: center;
  height: 24px;
  cursor: pointer;

  &_icon {
    margin-right: 8px;
  }

  &_info_block {
    margin-left: 8px;
  }
  &_disabled {
    cursor: not-allowed;
  }
}