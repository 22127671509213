.upload_image {
  &_box {
    min-height: 88px;
    border: 1px dashed #5C5C6E;
    border-radius: 8px;
    margin-bottom: 32px;
    cursor: pointer;
    position: relative;
    min-width: 823px;
    &__uploaded {
      border: 1px solid #CFCFD4;
      padding: 8px;
    }
    &__not_uploaded {
      border: 1px dashed #5C5C6E;
    }
  }
  &_inner {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
  &_media {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  }
}