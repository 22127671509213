@import '../../mixins';

.header {
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .logo {
    height: 35px;

    @include for-phone-only {
      transform: scale(0.8);
    }
  }

  .headerLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .avatar {
    transition: 0.4s;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    cursor: pointer;

    @include for-phone-only {
      position: relative;
      top: 16px;
    }
  }

  @include for-phone-only {
    padding: 0 10px;
    height: 58px;
    margin-bottom: 15px;
  }
  @include for-tablet-portrait-up {
    padding: 0 18px;
  }
  @include for-tablet-landscape-up {
    padding: 0 89px;
  }
  @include for-desktop-up {
    padding: 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1020px;
    margin: auto;

    @include for-desktop-down {
      width: 100%;
      margin: auto;
    }
  }
}


.container {
  scrollbar-width: none;

  &::-webkit-scrollbar { 
      display: none;
  }
}

.hamburgerMenu {
  border-radius: 100%;
  height: 50px;
  width: 50px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  position: relative;

  &.active {
    border-color: #0066B3;
  }

  .menu {
    position: absolute;
    z-index: 100;
    right: 0;
    top: calc(100% + 15px);
    background-color: white;
    
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #CFCFD4;
    max-height: 175px;
    overflow-y: auto;

    a {
      padding: 8px 16px;
      text-align: center;
      font-size: 16px;
      color: #404055;
      display: inline-block;
      white-space: nowrap;
      transition-duration: 0.3s;
      text-decoration: none;

      &:hover {
        background-color: #F5F5F6;
        color: #0066B3;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .logo {
    max-width: 240px;
  }

  .menu {
    width: 275px;
    max-height: 275px !important;

    a {
      display: block;
      white-space: wrap !important;
      width: 100%;
    }
  }
}

@include for-phone-only {
  .logo {
    max-width: 240px;
  }

  .menu {
    width: 275px;
    max-height: 275px !important; 

    a {
      display: block;
      white-space: wrap !important;
      width: 100%;
    }
  }
}