@import '../../mixins';

.container {
  margin: 0 auto;

  & .headline {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include for-phone-only {
    margin: 0 20px;
  }

  & .title {
    width: 100%;
    margin-bottom: 24px;
    overflow: hidden;
    word-break: break-all;
    text-align: center;
  }

  .carouselButtons {
    display: flex;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 16px;
    justify-content: center;
    column-gap: 16px;
  }
}

