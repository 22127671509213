@import '../../mixins';
.section {
  padding: 0;

  @include for-desktop-down {
    padding: 0 89px;
  }
  @include for-tablet-down {
    padding: 0 30px;
  }
  @include for-phone-only {
    padding: 0 10px;
  }
}

.container {
  max-width: 1020px;
  margin: 0 auto;
  flex-direction: column;
}

.chart_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 32px 70px;

  @include for-desktop-down {
    flex-direction: column;
    padding: 32px 20px;
    align-items: center;
  }
}

.counters {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slider_counters {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chart {
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include for-desktop-down {
    width: 500px;
    display: block;
    margin-top: 30px;
  }
  @include for-phone-only {
    width: 300px;
    display: block;
  }
}
