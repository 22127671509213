.profile {
  &_dropdown {
    top: 75px !important;
  }

  &_user {
    &_avatar {
      margin-right: 10px;
    }
    &_block {
      display: flex;
      padding: 0 16px;
      margin-bottom: 18px;
    }
    &_info {
      display: flex;
      flex-direction: column;
      width: 182px;
    }
    &_text {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.015em;
    }
    &__bold {
      font-weight: 700;
    }

    &__light {
      color: #5c5c6e;
    }
  }

  &_hr {
    border: none;
    border-top: 1px solid #ececee;
    color: #ececee;
    height: 1px;
    width: 100%;
    margin: 1px 0;
  }
}
