@import '../../mixins';

.card {
  width: 100%;
  position: relative;
  overflow: hidden;

  @include for-tablet-portrait-up {
    flex: 1 1 33.33%;
  }
}

.background {
  width: 100%;
  min-height: 167px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.background-1 {
  min-height: 280px;
}

.background-2 {
  min-height: 253px;
}

.text_block {
  width: 100%;
  height: 44px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}