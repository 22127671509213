.not_found {
  &_wrap {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &_image {
    margin-bottom: 40px;
  }
  &_title {
    font-size: 48px;
    margin-bottom: 32px;
  }
  &_descr {
    font-size: 24px;
    text-align: center;
    max-width: 870px;
  }
}