.container {
  max-width: 1909px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: auto;

  .pageWrapper {
    box-shadow: 0px 4px 15px 0px #00000033;
    overflow: hidden;
  }
}
