.product_value {
  &_heading {
    font-size: 12px;
    font-weight: 700;
    margin-right: 10px;
    &_left {
      display: flex;
    }
    &_block {
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
    }
  }
  &_delete_icon {
    fill: #DA1B5E;
    cursor: pointer;
  }
}