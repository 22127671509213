.hiddenHeaderCell
  background: #CCE1E9

.borderRight
  border-right: 2px solid #CCE1E9

// REFACTOR: rename to "numberBodyCell"
.numberBodyColumn
  text-align: right

.expandBodyCell
  padding: 0 !important

td.findersInTotalColumn
  border-left: 1px solid #E5F0F4
  border-right: 1px solid #E5F0F4
  > *
    color: #DA1B5E
