@import '../../mixins';
.section {
  @include for-phone-only {
    padding: 0 10px;
  }
  @include for-tablet-portrait-up {
    padding: 0 50px;
  }
  @include for-tablet-landscape-up {
    padding: 0 89px;
  }
  @include for-desktop-up {
    padding: 0;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1020px;
  margin: 0 auto;

  @include for-desktop-down {
    justify-content: space-between;
    width: 100%;
    margin: auto;
  }
  @include for-phone-only {
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: end;
  }
}

.card {
  width: 500px;
  margin-top: 100px;
  @include for-phone-only {
    width: 348px;
    margin-bottom: 50px;
  }
}