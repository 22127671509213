@import '../../mixins';
.section {
  padding: 0;

  @include for-desktop-down {
    padding: 0 89px;
  }
  @include for-tablet-down {
    padding: 0 20px;
  }
  @include for-phone-only {
    padding: 0 10px;
  }
}

.container {
  max-width: 1020px;
  margin: 0 auto;
  flex-direction: column;
}

.form_block {
  max-width: 586px;
  width: 100%;
  margin-bottom: 35px;
  @include for-desktop-down {
    max-width: 100%;
  }
}

.form_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @include for-desktop-down {
    flex-direction: column;
    align-items: center;
  }
}

.aside_block {
  width: 328px;
  min-height: 420px;
  background-color: #FFFFFF;
  padding: 24px 24px 35px;
  height: fit-content;
  @include for-desktop-down {
    width: 100%;
    min-height: auto;
  }
}
