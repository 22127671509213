.slider_number {
  &_wrap {
    width: 100%;
    max-width: 616px;
    margin: 20px auto;
  }

  &_text_wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 616px;
    margin: 0 auto;
  }
}
