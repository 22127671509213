.conditionalItem {
  .default {
    border: 1px solid #5C5C6E;
    border-radius: 8px;
    padding: 10px 15px;
    height: 40px;
  }

  .conditionalSelect {
    min-width: 180px;
  }

  .smallSelect {
    max-width: 90px;
    min-width: 70px;
  }
}

.elementContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  .selectItem {

    height: 40px;

    & > div:not([class]) {
      height: 40px;
    }

    &:first-child > div > div::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:last-child):not(:first-child) > div > div::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }

    &:last-child > div > div::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }

    &:first-child:last-child > div > div::before {
      border-radius: 8px;
      border-left: 1px solid #5C5C6E!important;
    }
  }

  .largeSelect {
    min-width: 195px;
  }

  .elementSelect {
    min-width: 300px;
  }
}

.conditionalOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  max-width: 100%;

  .text {
    width: calc(100% - 60px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .type {
    font-size: 12px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: white;

    &.A {
      background-color: #0092A3;
    }

    &.Q {
      background-color: #0092A3;
    }

    &.P {
      background-color: #005660;
    }

    &.CH {
      background-color: #404055;
    }

    &.C {
      background-color: #C3A314;
    }
  }
}

.flowImageOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  cursor: pointer;

  .flowImage {
    height: 50px;
    width: 70px;
    object-fit: cover;
  }

  .text {
    width: calc(100% - 85px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.inputItem {
  & > div > div::before{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
}