.tenant_card {
  min-height: 81px;
  width: 100%;
  padding: 16px 24px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 41.2%), linear-gradient(287.86deg, #282828 0%, #404055 48.28%, #141414 98.62%);
  border-radius: 8px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;

  &_column {
    display: flex;
    flex-direction: column;
    min-width: 110px;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }

  &_title {
    font-weight: 700;
    margin-bottom: 9px;
  }
}
