.product_range_saving {
  &_wrap {
    border-bottom: 1px solid #CFCFD4;
  }
  &_input_wrap {
    padding: 0 16px 16px 16px;
  }
  &_add_btn {
    height: 56px;
    padding: 18px;
    text-align: left;
  }
}

