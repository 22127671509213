@import '../../mixins';

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 110px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin: 20px auto 0;

  @include for-phone-only {
    margin-top: 20px;
  }

  @include for-tablet-portrait-up {
    margin: 20px 80px 0 80px;
  }

  @include for-desktop-up {
    width: 1020px;
    margin: 20px auto 0;
  }
}


.content {
  display: grid;
  column-gap: 109px;
  justify-content: center;
  align-items: center;
  height: 110px;
  grid-template-columns: 1fr 1fr;

  @include for-phone-only {
    column-gap: 0px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @include for-tablet-portrait-up {
    column-gap: 66px;
  }
}


.price {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: center;

  @include for-phone-only {
    grid-row: 1;
  }
}
