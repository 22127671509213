@import '../../../mixins';

.chart_products {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;

  @include for-phone-only {
    flex-direction: column;
  }

  &_item {
    display: flex;
    align-items: center;
    margin: 4px 15px 4px 0;
  }
}