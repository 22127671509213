.question_drop_box {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px solid #CFCFD4;
  padding: 0 18px;
  &:last-child {
    border-bottom: none;
  }
  &_header {
    height: 35px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    &_icon {
      width: 16px;
      height: 16px;
      margin-right: 11px;
    }
  }
  &_item {
    height: 24px;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    &_selected {
      color: #0092A3;
      font-weight: 700;
    }
  }
}
