.image_select_option {
  &_wrap {
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &_picture {
    width: 68px;
    height: 40px;
    margin-right: 16px;
    border-radius: 4px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &_text {
    width: calc(100% - 86px);
  }
}

.checkbox {
  width: 17px;
  height: 16px;
  border: 1px solid #191919;
  border-radius: 3px;
  margin-right: 16px;
  appearance: none;
  position: relative;
  cursor: pointer;

  &::before {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    width: 0.55em;
    height: 0.55em;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #191919;
  
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked::before {
    display: flex;
  }
}