.range_scoring {
  &_box {
    height: 70px;
    display: flex;
    align-items: center;
    padding: 16px;
  }
  &_name {
    font-weight: 700;
    width: 94px;
  }
  &_inputs {
    width: 192px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

