.tab_wrap {
  height: 36px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.tab_wrap__selected {
  border-bottom: 2px solid #DA1B5E;
}

.tab_variant_btn {
  height: 32px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  border-radius: 16px;
  padding: 8px 12px;
}
