.FilterValuesPopup
  animation-duration: 0.1s

  .ant-popover-inner
    background: linear-gradient(287.86deg, #282828 0%, #3F3F53 48.28%, #141414 98.62%)
    border-radius: 8px
    padding: 8px

  .ant-popover-title
    margin-bottom: 12px

  .ant-popover-title, .ant-popover-inner-content
    color: #fff
    min-width: unset

  ul
    list-style: none
    margin: 0
    padding: 0
    li
      display: flex
      align-items: center
      padding: 4px 0
      &:first-child
        padding-top: 0
      &:last-child
        padding-bottom: 0
      & > *
        transition: all 0.1s
      &:hover
        & > button
          opacity: 1
        &, & > span
          color: #E9769E
      button
        margin-left: 8px
        min-width: 16px
        min-height: 16px
        opacity: 0
        border: 1.5px solid currentColor
        border-radius: 50%
        display: flex
        justify-content: center
        align-items: center
        svg
          padding: 1.4px
