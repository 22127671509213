.valueTypeInput {
  display: flex;
  flex-direction: row;
  align-items: center;

  .valueTypeSelect {
    min-width: 190px;

    .react-select__control::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .valueContainer {
    height: 40px;
    border: 1px solid #5C5C6E;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 190px;
    position: relative;

    .valueContainerLabel {
      position: absolute;
      background-color: white;
      top: -10px;
      left: 16px;
      font-size: 12px;
      color: #9696a2;
    }

    input {
      outline: none;
      border: none;
      padding: 0 16px;
      appearance: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .inputType {
      user-select: none;
    }
  }
}