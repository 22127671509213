@import '../../mixins';

.section {
  @include for-phone-only {
    padding: 0 10px;
  }
  @include for-tablet-portrait-up {
    padding: 0 50px;
  }
  @include for-tablet-landscape-up {
    padding: 0 89px;
  }
  @include for-desktop-up {
    padding: 0;
  }
}

.container {
  max-width: 1020px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}