.settings_card {
  &_label {
    font-weight: 700;
    display: block;
    margin-bottom: 16px;
    &__regular {
      font-weight: 400;
    }
    &__no_margin_bottom {
      margin-bottom: 0;
    }
  }

  &_switch {
    margin-left: 8px;
  }
}