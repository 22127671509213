@import '../../mixins';

.wrap {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;

  @include for-phone-only {
    padding: 20px 30px;
    margin: 0 16px;
    width: auto;
  }
  @include for-tablet-portrait-up {
    margin: 0 50px;
    width: auto;
  }
  @include for-tablet-landscape-up {
    margin: 0 89px;
    width: auto;
  }
  @include for-desktop-up {
    max-width: 1020px;
    margin: 0 auto;
    width: 100%;
  }
}

.chapters_button_wrap {
  @include for-phone-only {
    padding: 20px 0;
    margin: 0 16px;
    width: auto;
  }
}

.nextPrevButtons {
  width: 100%;
  z-index: 5;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(3.5px);
  position: fixed;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
}