.variablePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;

  .deleteBody {
    margin-top: 24px;
    color: #5C5C6E;
    line-height: 24px;
  }

  .buttonsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    gap: 12px;
    margin-top: 36px;

    & > * {
      width: 100%;
    }
  }

  .body {
    background-color: white;
    width: 800px;
    padding: 18px 24px;
    border-radius: 8px;

    &.deletePopup {
      max-width: 520px;
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #191919;
      font-weight: bold;
      font-size: 24px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .inputContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin: 24px 0;
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;

      button {
        width: 240px;
      }
    }
  }
}