@import '../../../mixins';


.slick-active {
  padding: 20px 0;
}

.slick-center {
  transform: scale(1.08)
}

.slick-slide:not(.slick-active) {
  margin: 20px 0;
}

.child {
  width: 100%;
}

.slide:not(.slick-active) {
  cursor: pointer;
}
