@import '../../../../mixins';

.buttons {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(178px, 1fr) );
  row-gap: 5px;
  column-gap: 16px;
  margin-bottom: 20px;
  @include for-phone-only {
    margin-top: 52px;
    place-self: center;
    padding: 0 30px;
    grid-template-columns: repeat( auto-fill, minmax(178px, 300px) );
    row-gap: 16px;
    & button {
      height: 40px;
      border-radius: 8px;
    }
  }
}