@import '../../../mixins';

.row_content {
  display: flex;
  justify-content: space-between;
  @include for-phone-only {
  flex-direction: column;
  }
}

.input_full_width {
  width: 100%;
}

.input_half_width {
  width: calc(50% - 8px) !important;
  @include for-phone-only {
    width: 100% !important;;
  }
}