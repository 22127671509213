@import '../../mixins';

.chapterContainer {
  max-width: 1020px;
  margin: 0 auto;
  width: 100%;

  @include for-desktop-down {
    width: 100%;
    padding: 0 89px;
  }

  @include for-tablet-down {
    width: 100%;
    padding: 0 12px;
  }

  //@include for-phone-only {
  //  margin: 0 12px;
  //}
  //@include for-tablet-portrait-up {
  //  margin: 0 12px;
  //}
  //@include for-tablet-landscape-up {
  //  margin: 0 89px;
  //}
  //@include for-desktop-up {
  //  max-width: 1020px;
  //  margin: 0 auto;
  //}

  & .firstChapter {
    width: 100%;
    min-height: 300px;
    height: 45vh;
    max-height: 450px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 40px 0 32px 0;
    display: flex;
    padding: 50px 20px 20px;
    position: relative;

    @include for-phone-only {
      margin: 22px 0 17px;
      padding: 0;
      height: auto !important;
      min-height: 178px;
      background-size: 100% 178px;
      background-position: center top;
      max-height: initial;
      background-size: contain;
    }
    @include for-tablet-portrait-up {
      margin: 24px 0 16px 0;
      padding: 50px 20px 20px;
    }
    @include for-tablet-landscape-up {
      margin: 0 0 16px 0;
      padding: 20px;
    }

    & .firstChapterInfo {
      width: 496px;
      height: fit-content;
      min-height: 218px;
      max-height: 100%;
      overflow-y: hidden;
      padding: 24px;
      padding-bottom: 6px;

      &:hover {
        overflow-y: auto;
      }

      @include for-tablet-down {
        width: 394px;
      }

      @include for-phone-only {
        padding: 16px;
        width: 100%;
        margin-top: 178px;
      }
    }
  }

  & .notFirstChaptersContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    column-gap: 16px;

    @include for-phone-only {
      flex-direction: column;
      row-gap: 16px;
    }

    @include for-tablet-portrait-up {
      row-gap: 14px;
      flex-wrap: wrap;
    }

    @include for-desktop-up {
      flex-wrap: nowrap;
    }
  }
}
