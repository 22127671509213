.color_picker {
  &_form_item {
    width: calc((100% - 48px)/3);
    margin-right: 16px;
    margin-bottom: 0;
    &:last-child {
      margin-right: 0;
    }
  }

  &_wrap {
    height: 56px;
    width: 100%;
    border: 1px solid #CFCFD4;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
    margin-bottom: 10px;
    &_disabled {
      cursor: not-allowed;
    }
  }
}