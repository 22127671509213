.QuestionnaireVersions
  .react-select__menu
    margin: 4px 0 0
    border: 0
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2)
    min-width: unset
    width: max-content

  .react-select__single-value
    color: #fff

  .react-select__indicators *
    fill: #fff

  .react-select__value-container
    padding: 6px 16px

  .react-select__control--is-disabled
    background: transparent
