.questions_scoring {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.questions_drops_wrap {
  border-right: 1px solid #CFCFD4;
  width: 120px;
  padding: 20px 0;
  height: calc(100vh - 220px);
  overflow-y: auto;
}


.products_score {
  &_wrap {
    display: flex;
    flex-direction: column;
    width: calc(100% - 120px);
  }
  &_clear_btn {
    height: 56px;
    padding: 18px;
    border-bottom: 1px solid #CFCFD4;
    text-align: right;
  }
  &_description {
    margin-top: 20px;
    font-weight: 700;
    text-align: center;
  }
  &_block {
    overflow-y: auto;
    height: calc(100vh - 300px);
  }
  &_add_btn {
    height: 56px;
    padding: 18px;
    text-align: left;
  }
}
