@import '../../mixins';

.footer {
  & .container {
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 30px;

    @include for-phone-only {
      column-gap: 12px;
    }
  }
}
