.AppNewDatePicker
  z-index: 1000

.react-datepicker__header
  background-color: #f9f9fa
  border-bottom: 1px solid #5C5C6E
  padding: 12.5px 15px

.react-datepicker
  border: 1px solid #5C5C6E
  border-radius: 8px
  overflow: hidden

.react-datepicker__month
  padding: 11px
  margin: 0

.react-datepicker__day-names
  margin: 0

.react-datepicker__day-name
  font-family: 'SimonKucher', serif
  font-weight: 700
  width: 33px
  margin: 3px
  text-transform: capitalize
  line-height: 1

.react-datepicker__day
  font-family: 'SimonKucher', serif !important
  font-weight: 400
  font-size: 14px !important
  line-height: 1
  color: #191919 !important
  outline: none
  border: none
  letter-spacing: -0.15
  height: 33px !important
  width: 33px !important
  margin: 3px
  display: flex
  justify-content: center
  align-items: center

  &:hover
    border-radius: 4px !important
    background-color: #f4bace
    color: #fff !important

  &.react-datepicker__day--outside-month
    color: #858899 !important

  &.react-datepicker__day--in-selecting-range
    background-color: #f4bace

.react-datepicker__day--disabled
  color: #ccc !important

.react-datepicker__day--keyboard-selected
  background-color: white
  border-radius: 4px !important

.react-datepicker__month-container
  display: flex
  flex-direction: column

.react-datepicker__week
  display: flex

.react-datepicker__day--in-range
  z-index: 10 !important
  position: relative
  height: 1.7rem
  color: #191919 !important
  font-weight: 400

  &, &:hover
    background-color: #f4bace

.react-datepicker__day--selected,
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end
  height: 1.7rem
  border-radius: 4px !important
  color: #fff !important
  font-weight: 700 !important
  border: none

  &, &:hover
    background-color: #da1b5e !important

.react-datepicker__navigation
  outline: none

.react-datepicker__day--today
  font-weight: 700

.calendar
  .react-datepicker__current-month
    width: 171px
    height: 32px
    margin: 8px auto 0 !important
    line-height: 32px
    border-radius: 99px
    font-weight: 500
    font-size: 14px
    letter-spacing: 0.12px
    color: #191919 !important

  .react-datepicker__navigation--next
    right: 75px !important
    top: 25px !important
    outline: none !important
    border-right-color: red !important

  .react-datepicker__navigation--previous
    left: 75px !important
    top: 25px !important
    outline: none !important

.react-datepicker__current-month
  font-weight: 700
  font-size: 16px
  letter-spacing: -0.15px
  color: #191919 !important

.react-datepicker__triangle
  display: none

.react-datepicker__month-wrapper
  height: 70px !important

.react-datepicker__month-text--keyboard-selected
  background-color: #da1b5e !important

  &:hover
    background-color: #ffcdd9 !important

.react-datepicker__month-text
  padding: 15px 0

  &:hover
    background-color: #ffcdd9 !important
