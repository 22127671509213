$deleteButtonBg: #F2F7F9
$tableBorder: 1px solid #E3E3E6

.dragRowHandleCellWidth
  width: 25px !important
  max-width: 25px !important

.deleteRowCellWidth
  width: 50px !important
  max-width: 50px !important

.bodyRow
  &:first-child .bodyDeleteRowCell > *
    border-top-width: 1px
    border-radius: 8px 0 0 0

  &:last-child .bodyDeleteRowCell > *
    border-radius: 0 0 0 8px

  &:last-child .bodyInputCell:last-child > *
    border-radius: 0 0 8px 0

.bodyInputCell > *
  padding: 5px
  border-right: $tableBorder
  border-bottom: $tableBorder
  height: 100%
  display: flex
  align-items: center

.headerDeleteCell
  > *
    background: $deleteButtonBg
    padding: 10px 0
    border: $tableBorder
    border-left-width: 0
    display: flex
    justify-content: center
    margin-bottom: -1px

  &:nth-child(3) > *
    border-left-width: 1px
    border-top-left-radius: 8px
    margin-left: -1px

  &:last-child > *
    border-top-right-radius: 8px

.bodyDeleteRowCell
  @extend .deleteRowCellWidth
  > *
    height: 100%
    background: $deleteButtonBg
    padding: 0 18px
    border: $tableBorder
    border-top-width: 0
    display: flex
    justify-content: center
    align-items: center

.bodyDragRowCell
  @extend .dragRowHandleCellWidth
  & > *
    display: flex
    justify-content: center
    width: 100%

.circle
  margin-right: 8px

.circleInner
  width: 22px
  height: 22px
  display: flex
  border-radius: 50%
  border: 1px solid #fff

.circleImage
  padding: 2px

.rowColorActive
  border-radius: 0 8px 8px 0 !important
