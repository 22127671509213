.root
  position: relative
  display: flex

  &:hover
    .customCheckbox
      border-color: #ba1750

    svg, svg *
      fill: #BA1750

    .label
      color: #BA1750

  &:active
    .customCheckbox
      border-color: #E1497E

    svg, svg *
      fill: #E1497E

    .label
      color: #191919

.disabled
  cursor: not-allowed

.content
  display: flex
  align-items: center

.customCheckbox
  display: flex
  align-items: center
  border: 1px solid #191919
  height: 16px
  width: 16px
  border-radius: 2px
  justify-content: center
  min-height: 16px
  min-width: 16px

  svg
    visibility: hidden

.input
  position: absolute
  inset: 0
  z-index: 1
  cursor: pointer
  opacity: 0
  margin: 0
  height: 100%
  width: 100%
  padding: 0

  &:checked ~ .content .customCheckbox
    svg
      visibility: visible


.label
  margin-left: 8px
  font-weight: normal !important
  line-height: 1
