.flow_process {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.flow_drops_wrap {
  border-right: 1px solid #CFCFD4;
  width: 120px;
  padding: 10px 12px;
  height: calc(100vh - 235px);
  overflow-y: auto;
}


.flow_selects {
  &_wrap {
    display: flex;
    flex-direction: column;
    width: calc(100% - 120px);
    height: calc(100vh - 235px);
    overflow-y: auto;
  }
  &_no_select {
    text-align: center;
    margin: 16px auto;
    font-weight: 700;
  }
  &_clear_btn {
    height: 55px;
    padding: 18px;
    border-top: 1px solid #CFCFD4;
    text-align: right;
  }
  &_logic_wrap {
    border-top: 1px solid #CFCFD4;
  }
}


.flow_process_box {
  padding: 24px 15px;
  border-bottom: 1px solid #CFCFD4;
  &:last-child {
    border-bottom: none;
  }
  &_select_title {
    display: block;
    margin-bottom: 8px;
    font-weight: 700;
  }
  &_delete_icon {
    fill: #5c5c6e;
    cursor: pointer;
    &:hover {
      fill: #DA1B5E;
    }
  }
}

.flow_process_counter_ranges {
  border-bottom: 1px solid #CFCFD4;
  &:last-child {
    border-bottom: none;
  }
}

.flow_process_button_box {
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  &_link {
    margin-bottom: 12px;
    & label {
      left: 40px;
    }
  }
  &_text {
    margin-left: 10px;
  }
  &_result_depot {
    &:last-child {
      margin-bottom: 30px;
    }
  }
}

.draggable_rule {
  &_wrap {
    position: relative;
    padding: 16px 0;
    //z-index: 1;
    border-bottom: 1px solid #CFCFD4;
    &:last-child {
      border-bottom: none;
    }
  }
}

.buttonRules {
  .separator {
    height: 1px;
    width: 100%;
    background-color: #CFCFD4;
  }

  .title {
    font-weight: 700;
    color: #191919;
    margin-top: 16px;
  }

  .buttonRow {
    margin-top: 16px;
  }

  .buttonRuleSelector {
    padding-left: 20px;
    margin-top: 16px;
  }
}