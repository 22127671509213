body {
  margin: 0;
  position: relative;
  font-family: SimonKucher, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

.flex-x-c {
  display: flex;
  justify-content: center;
}

// scrollbar
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

// Scrollbar for Firefox
html, section, ul, div {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin !important;
  -webkit-tap-highlight-color: transparent;
}

#root {
  display: flex;
  min-height: 100%;

  // Need to be larger than "0" because some functionality (like generating PDF from "End User Flow" Results' product) should be located under the main content ("#root)
  z-index: 2;
  position: relative;
}

.primary_color {
  color: #da1b5e;
}

.primary_color_disabled {
  color: #f4bace;
}

.primary_text_color {
  color: #191919;
}

.primary_text_color_disabled {
  color: #858899;
}

.white_color {
  color: #ffffff;
}

.secondary_color {
  color: #5c5c6e;
}

.bold {
  font-weight: 700;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-y-c {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.flex-sb {
  justify-content: space-between;
}

.space_between {
  display: flex;
  justify-content: space-between;
}

.align_items_center {
  display: flex;
  align-items: center;
}

.stretch_first_child {
  display: flex;
}

.stretch_first_child .ant-space-item {
  &:first-child {
    flex: 1;
  }
}

.equal_space {
  width: 100%;
  & > .ant-space-item {
    display: flex;
    flex: 1;
  }
}

.file_uploader {
  animation: loadingCircle 1s infinite linear;
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// layout
.ant-layout {
  background: transparent;
}

// typography
.ant-typography {
  letter-spacing: -0.015em;
}
h1.ant-typography {
  font-size: 48px;
}

h4.ant-typography {
  font-size: 20px;
  margin: 0;
}

.ant-typography strong {
  font-weight: 700;
}

.ant-card .ant-card-head {
  padding: 24px;
}

//buttons
.ant-btn-primary:not(:disabled):hover {
  background-color: #ba1750;
}

.ant-btn-primary:not(:disabled):active {
  background-color: #e9769e;
}

.ant-btn-primary:disabled {
  color: #ffffff;
  //background: #f4bace;
}

.ant-btn-default {
  border-color: #191919;
  font-weight: 700;
}

.ant-btn-default:not(:disabled):hover {
  color: #831038;
  border-color: #831038;
}

.ant-btn-default:not(:disabled):active {
  color: #e1497e;
  border-color: #e1497e;
}

.ant-btn-default:disabled {
  border-color: #858899;
  color: #858899;
  background-color: #ffffff;
}

// table
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 0;
  background: #e5f0f4;
  overflow: hidden;
  border: none;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 14px 24px;
  vertical-align: top;
  min-width: 105px;
}

@media screen and (max-width: 1300px) and (min-width: 1024px) {
  .ant-table-wrapper .ant-table-tbody > tr > td {
    min-width: 60px !important;
  }
}

.ant-table-tbody > tr.ant-table-row:hover {
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: transparent;
}

.ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td:first-child {
  border-radius: 0;
}

.ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td:last-child {
  border-radius: 0;
}

.ant-table {
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
}

// modal mask
.ant-modal-root .ant-modal-mask {
  background: rgba(0, 0, 0, 0.8);
}

.ant-modal .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin: 0;
}

// select placeholder
.ant-select .ant-select-selection-placeholder {
  font-weight: 400;
  color: #cfcfd4;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-inline-start: 16px;
  padding-inline-end: 32px;
}

// checkbox
.ant-checkbox-checked,
.ant-select-tree-checkbox-checked {
  .ant-checkbox-inner,
  .ant-select-tree-checkbox-inner {
    background: no-repeat center url("~/public/icons/checkmark.svg") !important;
  }
}

.ant-select-tree-switcher {
  display: none;
}

.ant-checkbox,
.ant-select-tree-checkbox {
  margin: 0 !important;
  .ant-checkbox-inner,
  .ant-select-tree-checkbox-inner {
    transition: 0.1s ease all !important;
    background-color: unset !important;
    border-width: 1px !important;
    border-color: #191919 !important;
    height: 16px !important;
    width: 16px !important;
    border-radius: 2px !important;

    &:after {
      display: none !important;
    }
    &:hover {
      border-color: #ba1750 !important;
    }
  }
  &:after {
    border-radius: 2px !important;
    border-width: 1px !important;
  }
  *[class*="checkbox-inner"] {
    border-width: 1px;
    border-color: #191919;
    height: 16px !important;
    width: 16px !important;
    border-radius: 2px !important;
    background-color: unset !important;
    &:hover {
      border-color: #ba1750 !important;
    }
  }
}

//switch
.ant-switch {
  height: 24px;
  width: 40px;
  background: #ffffff;
  border: 1px solid #5c5c6e;
  &:hover {
    border: 1px solid #191919;
  }
}

.ant-switch.ant-switch-small {
  height: 18px;
  width: 26px;
  min-width: 26px;
  line-height: 16px;
}

.ant-switch.ant-switch-checked {
  border: 1px solid #da1b5e;
  &:hover {
    border: 1px solid #ba1750;
  }
}

.ant-switch:hover:not(.ant-switch-disabled),
.ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #ffffff;
}

.ant-switch .ant-switch-handle {
  width: 18px;
  height: 18px;
}

.ant-switch.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}

.ant-switch .ant-switch-handle::before {
  background-color: #5c5c6e;
  border-radius: 9px;
}

.ant-switch:hover .ant-switch-handle::before {
  background-color: #191919;
}

.ant-switch.ant-switch-checked .ant-switch-handle::before {
  background-color: #da1b5e;
}

.ant-switch.ant-switch-checked:hover .ant-switch-handle::before {
  background-color: #ba1750;
}

.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 20px);
}

.ant-switch.ant-switch-small.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 14px);
}

//form item
.ant-form-item-feedback-icon-success {
  & > span {
    background: no-repeat center url("~/public/icons/checkmark-circle.svg") !important;
    svg {
      display: none !important;
    }
  }
}

.ant-form-item-feedback-icon-warning {
  & > span {
    background: no-repeat center url("~/public/icons/warning.svg") !important;
    svg {
      display: none !important;
    }
  }
}

.ant-form-item-feedback-icon-error {
  & > span {
    background: no-repeat center url("~/public/icons/alert.svg") !important;
    svg {
      display: none !important;
    }
  }
}

.ant-form-item-feedback-icon-validating {
  svg {
    fill: #0092a3;
  }
}

*[class*="ant-form-item-feedback-icon"] {
  cursor: initial;
  & > span {
    height: 16px;
    width: 16px;
  }
}

[class^="ant-input"], [class*=" ant-input"] {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

.ant_input {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

// select and datepicker
.ant-picker-panel-container,
.ant-select-dropdown {
  padding: 16px 20px;
  min-width: unset !important;
  border: 1px solid #5c5c6e;
  border-radius: 8px;
  box-shadow: none !important;
}

// datepicker
.ant-picker-input .ant-picker-clear {
  position: static;
  margin-left: 10px;
  transform: none;
}

.ant-picker-dropdown {
  .ant-picker-header-super-prev-btn {
    display: none;
  }

  .ant-picker-header-super-next-btn {
    display: none;
  }

  .ant-picker-panel,
  .ant-picker-year-panel,
  .ant-picker-decade-panel,
  .ant-picker-month-panel {
    max-width: 300px;
    width: 100%;
  }

  .ant-picker-decade-panel .ant-picker-header-view {
    padding: 16px;
  }

  .ant-picker-panel-container {
    padding: 0;
  }

  .ant-picker-header-view {
    line-height: unset;
  }

  .ant-picker-cell {
    &:hover:not(.ant-picker-cell-selected) {
      .ant-picker-cell-inner {
        background-color: #ffcdd9 !important;
      }
    }

    .ant-picker-cell-inner {
      font-size: 14px;
      // TODO: enable
      //width: 33px;
      //height: 33px;
    }

    &.ant-picker-cell-selected .ant-picker-cell-inner {
      font-weight: 700;
    }
  }

  .ant-picker-date-panel {
    width: 100%;

    .ant-picker-header {
      border: none;
      background-color: #f9f9fa;
      line-height: 19px;
      padding: 16px 6px 16px;

      button {
        line-height: unset;
      }
    }

    .ant-picker-body {
      padding: 0;

      thead {
        background-color: #f9f9fa;
        border-bottom: 1px solid #5c5c6e;

        tr th {
          font-weight: 700;
          height: unset;
          padding-bottom: 16px;
          font-size: 14px;
          width: unset;
        }
      }

      .ant-picker-content {
        width: 100%;
      }
    }
  }
}

//pagination
.ant-pagination .ant-pagination-item-active a,
.ant-pagination .ant-pagination-item-active:hover a {
  color: #191919;
}

.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:hover {
  font-weight: 700;
  border-color: #191919;
}

.ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #191919;
}

//notification
.ant-notification
  .ant-notification-notice {
    padding: 16px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.04) 0%,
        rgba(255, 255, 255, 0) 41.2%
      ),
      linear-gradient(287.86deg, #282828 0%, #404055 48.28%, #141414 98.62%) !important;
}

.ant-notification
  .ant-notification-notice
  .ant-notification-notice-close {
    width: auto;
    height: auto;
    top: 16px;
}

.ant-notification
  .ant-notification-notice
  .ant-notification-notice-with-icon
  .ant-notification-notice-message {
    margin-bottom: 0;
    margin-inline-start: 32px;
    font-size: 16px;
}

.ant-notification
  .ant-notification-notice
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
    margin-inline-start: 32px;
}

// REFACTOR: project styles. Learn how to customize Ant Design theme properly
.ant-form-item-has-error
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  border-color: #ff4949;
}

.ant-upload-wrapper {
  max-width: 100%;
  display: block;

  .ant-upload-select {
    width: 100%;
  }
}

.QuestionnaireLeftSideBarTabs.ant-tabs {
  .ant-tabs-tab {
    padding: 16px;
  }

  .ant-tabs-tab-btn {
    color: #cfcfd4;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: white;
    }
  }
}

.ant-tooltip {
  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    background: #191919;
    padding: 8px;
    border-radius: 8px;
    min-height: unset;
  }
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  &, &:hover, &:focus {
    animation-name: onAutoFillStart;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 50000s ease-in-out 0s;
  }
}

input:not(:-webkit-autofill), textarea:not(:-webkit-autofill), select:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

@keyframes onAutoFillStart {
  from {
  }

  to {
  }
}

@keyframes onAutoFillCancel {
  from {
  }

  to {
  }
}

// popup
.ant-popover-arrow {
  display: none !important;
}

//slider
.ant-slider .ant-slider-handle {
  width: 8px;
  height: 8px;
  &::before {
    content: none;
  }
  &::after {
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 8px;
    height: 8px;
    background: #00acc0;
    box-shadow: none;
    border-radius: 50%;
  }
}

.ant-slider .ant-slider-handle:hover::after,
.ant-slider .ant-slider-handle:active::after,
.ant-slider .ant-slider-handle:focus::after {
  width: 8px;
  height: 8px;
  box-shadow: none;
  inset-inline-start: 0;
  inset-block-start: 0;
}

.ant-slider:hover .ant-slider-handle::after {
  box-shadow: none;
}

.ant-slider-horizontal .ant-slider-handle {
  inset-block-start: 2px;
}

.ant-slider-horizontal .ant-slider-rail,
.ant-slider-horizontal .ant-slider-track {
  height: 2px;
}

.ant-slider-horizontal .ant-slider-step {
  height: 2px;
}

.ant-slider .ant-slider-track,
.ant-slider:hover .ant-slider-track {
  background-color: #00acc0;
}

.ant-slider .ant-slider-rail,
.ant-slider:hover .ant-slider-rail {
  background-color: #ffffff;
}

.ant-card .ant-card-head .ant-card-head-title {
  overflow: visible;
}

//collapse
.ant-collapse {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 8px 24px 8px 8px;
  display: flex;
  align-items: center;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  color: initial;
}
