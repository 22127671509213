.image_select {
  &_wrap {
    width: 100%;
    height: 120px;
    border-radius: 8px;
    padding: 7px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    margin-bottom: 32px;
    &:hover {
      border: 1px solid #00ACC0;
    }
    &__open {
      border: 1px solid #00ACC0;
    }
    &__closed {
      border: 1px solid #5C5C6E;
    }
    &.disabled {
      border: 1px solid #5C5C6E;
      cursor: not-allowed;
      background: #F2F2F3;
    }
  }
  &_picture {
    width: 204px;
    height: 104px;
    margin-right: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    &__not_selected {
      background: #F2F2F3;
    }
    &.disabled:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: #dadadaa8;
    }
  }
  &_text {
    width: calc(100% - 254px);
    margin-right: 8px;
    &_label {
      width: calc(100% - 254px);
      pointer-events: none;
      transition: all 0.2s ease 0s;
      color: #9696A2;
      &__selected {
        width: auto;
        position: absolute;
        font-size: 12px;
        top: -12px;
        left: 16px;
        background: white;
        color: #9696A2;
      }
    }
  }
  &_options_wrap {
    margin-top: 10px;
    height: 100%;
    overflow-y: scroll;
  }
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  max-height: 404px;
  min-height: 246px;
  padding: 12px 8px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.controls {
  position: absolute;
  top: 5px;
  left: 7px;
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #CFCFD4;
  box-sizing: border-box;

  .control {
    height: 100%;
    min-width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: white;
    cursor: pointer;

    &.middle {
      padding-left: 7px;
      padding-right: 7px;
      border-left: 1px solid #CFCFD4;
      border-right: 1px solid #CFCFD4;
      font-size: 10px;
      color: #5C5C6E;
      cursor: default;
    }
  }
}

.active {
  color: #5C5C6E;
  font-size: 10px;
  border: 1px solid #CFCFD4;
  padding: 2px 7px;
  position: absolute;
  background-color: white;
  top: 5px;
  right: 7px;
  border-radius: 4px;
}

.overflowedTitlesCount {
  color: #DA1B5E;
  font-size: 16px;
  font-weight: 700;
}