@import '~src/lib/styles/mixins';
@import '~src/lib/styles/breakpoints';

:root {
  --table_row_padding: 18px;
}

$table_row_border_radius: 6px;
$fixed_cell_width: 320px;

@container (min-width: 20px) {
  :root {
    --table_row_padding: 10px;
  }
}

$table_row_padding: var(--table_row_padding);

.root {
  display: flex;
  justify-content: center;

  :global {
    .AppText {
      @include for-phone-only {
        font-size: 12px !important;
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.content {
  max-width: 95%;
  width: 100%;
  padding: 11px;
  padding-top: 0;
}

.headlineWrapper {
  display: flex;
  justify-content: center;
}

.headline {
  display: flex;
  align-items: center;
  position: relative;
}

.headlineInfoIcon {
  position: absolute !important;
  top: 0;
  right: 0;
  > * {
    position: static;
  }
}

// Row paddings and borders radius
.table tr {
  //&:not(.productsRow) td:first-child {
  & td:first-child {
    padding-left: $table_row_padding !important;
    padding-top: 0 !important;
    flex: 2;
    max-width: 230px;
    background-color: white !important;
  }

  &:not(.productsRow) td:last-child {
    padding-right: $table_row_padding !important;
    background-color: white !important;
    padding-top: 0 !important;
  }

  &.productsRow td:first-child .fixedCell {
    @include for-desktop-up {
      //width: calc(#{$fixed_cell_width} + (#{$table_row_padding}));
      width: 100%;
    }
  }

  &.productsRow td:first-child {
    flex: 2 1;
    max-width: 230px;
  }

  &.productsRow td:last-child {
    padding-right: 18px !important;
    background-color: white !important;
  }


  &.lastPlainRow {
    td:first-child {
      padding: 0 0 $table_row_padding $table_row_padding !important;
      border-bottom-left-radius: $table_row_border_radius !important;
    }
    td:last-child {
      padding: 0 $table_row_padding $table_row_padding 0 !important;
      border-bottom-right-radius: $table_row_border_radius !important;
    }
    td:not(:first-child):not(:last-child)  {
      padding: 0 0 $table_row_padding !important;
    }
  }

  &.lastPlainOddRow {
    td:first-child {
      padding: 0 0 0 $table_row_padding !important;
    }
    td:last-child {
      padding: 0 $table_row_padding 0 0 !important;
    }
    td:not(:first-child):not(:last-child)  {
      padding: 0 !important;
    }
  }
}

.table {
  :global {

    table {
      min-width: auto;
    }

    .ant-table {
      background: transparent;
      filter: unset;
    }

    tr {
      background: unset !important;
      display: flex;
      flex-direction: row;
    }

    thead {
      display: none;
    }

    .ant-table-measure-row td {
      background: unset !important;
    }

    tr, td {
      border: none !important;
    }

    td {
      background: #fff !important;
      padding: 0 !important;
      height: inherit;
      flex: 1;
    }

    .ant-table-tbody > tr.ant-table-row:hover {
      filter: unset;
      background: unset;
      td {
        border-radius: unset;
      }
    }
  }
}

.productsRow .cell {
  align-items: flex-end;
}

.transparentRow {
  background: transparent;
  height: auto;

  td {
    background: unset !important;
  }

  td:after {
    display: none;
  }
}

.tableNameRow .cell {
  justify-content: flex-start;
}

.tableNameNotFirst td  {
  padding-top: 16px !important;
}

.tableName {
  font-size: 18px;
  margin: 0 !important;

  @include for-phone-only {
    font-size: 12px !important;
  }
}

.cell {
  position: relative;
  padding: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(.fixedCell) {
    //width: 165px !important;
  }
}

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  & > span {
    min-height: 39px;
  }
}

.productImageWrapper {
  width: 92px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;

  @include for-desktop-up {
    width: 116px;
    height: 76px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.icon {
  width: 16px;
  height: 16px;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.picture {
  width: 70px;
  border-radius: 4px;
  overflow: hidden;

  @include for-desktop-up {
    width: 90px;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
}