.bullet_component_icon {
  &_image {
    max-height: 24px;
    max-width: 24px;
    overflow: hidden;
  }
  &_svg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    overflow: hidden;
  }
}
