.template_card {
  &_headline {
    font-weight: 700;
    margin-bottom: 24px;
    display: block;
  }
  &_header_block {
    padding: 20px 25px 0 25px;
    border-bottom: 1px solid #E3E3E6;
  }
  &_content_block {
    padding: 20px 25px;
    border-bottom: 1px solid #E3E3E6;
    &:last-child {
      border-bottom: none;
    }
  }
  &_bottom_block {
    padding: 20px 25px 0 25px;
  }
}

.question_answer_step_block {
  display: flex;
  max-width: 125px;
  &_info_icon {
    cursor: pointer;
    margin-right: 8px;
    margin-top: 4px;
    svg:hover {
      fill: #DA1B5E;
    }
  }
}

.question_answer_currency_symbol_block {
  display: flex;
  margin-right: 24px;
}
