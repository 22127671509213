$breakpoint-phone: 599px;
$breakpoint-tablet-portrait: 600px;
$breakpoint-tablet-landscape: 900px;
$breakpoint-desktop: 1024px;


:export {
  phone: $breakpoint-phone;
  tabletPortrait: $breakpoint-tablet-portrait;
  tabletLandscape: $breakpoint-tablet-landscape;
  desktop: $breakpoint-desktop;
}
