// REFACTOR: use this file (using absolute imports) instead of that deep lying one

@import 'breakpoints';

@mixin for-phone-only {
  @container (max-width: #{$breakpoint-phone}) { @content; }
}

@mixin for-tablet-portrait-up {
  @container (min-width: #{$breakpoint-tablet-portrait}) { @content; }
}
@mixin for-tablet-landscape-up {
  @container (min-width: #{$breakpoint-tablet-landscape}) { @content; }
}
@mixin for-desktop-up {
  @container (min-width: #{$breakpoint-desktop}) { @content; }
}


@mixin for-tablet-down {
  @container (max-width: #{$breakpoint-tablet-landscape}) { @content; }
}

@mixin for-desktop-down {
  @container (max-width: #{$breakpoint-desktop}) { @content; }
}
