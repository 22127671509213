.items {
  position: absolute;
  top: 0px;
  right: -110px;
  padding-left: 10px;
  padding-right: 15px;
  border: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  & > * {
    border: 1px solid #191919;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
    cursor: pointer;
    margin-right: 5px;
    &:last-child {
      margin-right: 0px;
    }
    &:hover {
      border: 1px solid #DA1B5E;
    }
    &:disabled {
      border-color: currentColor;
    }
    &:hover svg {
      & path {
        fill: #DA1B5E;
      }
    }
  }
}
