@import '../../../mixins';
.section {
  @include for-phone-only {
    padding: 0 10px;
  }
  @include for-tablet-portrait-up {
    padding: 0 50px;
  }
  @include for-tablet-landscape-up {
    padding: 0 89px;
  }
  @include for-desktop-up {
    padding: 0;
  }
}

#previewContentBlock {
  max-width: 1020px !important;
  margin: 0 auto;
  margin-bottom: 32px !important;
  margin-top: 32px !important;
  padding-top: 0 !important;
}

.container {
  @include for-phone-only {
    padding: 0 10px;
    margin: 24px 12px;
  }
  @include for-tablet-portrait-up {
    padding: 0 10px;
    margin: 24px 12px;
  }
  @include for-tablet-landscape-up {
    margin: 32px 89px;
  }
  @include for-desktop-up {
    max-width: 1020px !important;
    margin: 32px auto;
    width: 100%;
  }
}

.content_blocks {
  @include for-desktop-down {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
}

.content_block {
  @include for-desktop-down {
    width: 500px !important;
  }
  @include for-phone-only {
    width: 90% !important;
    max-width: 354px;
  }
}

.horizontal_content_block {
  @include for-tablet-down {
    flex-direction: column !important;
  }
}

.horizontal_background_block {
  @include for-tablet-down {
    min-height: 200px;
    width: 100% !important;
  }
}

.bullet_points {
  max-width: 1020px !important;

  @include for-tablet-down {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
  }

  @include for-desktop-down {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
  }
}

.horizontal_bullet_points {
  max-width: 1020px !important;

  @include for-tablet-down {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
  }

  @include for-desktop-down {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
  }
}

.bulletPointContainer {
  .bulletPointHeader,
  .bulletPointContent {
    display: flex;
    gap: 8px;

    & > div {
      flex: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      .contentItemText {
        position: relative;

        .contentInfoIcon {
          position: absolute;
          right: 9px;
          bottom: 20px;
        }
      }
    }
  }

  .bulletItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;

    .bulletContent {
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }
}