.header {
  height: 51px;
  min-height: 51px;
  padding-inline: 24px;
  min-width: 1350px;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 41.2%), linear-gradient(287.86deg, #282828 0%, #404055 48.28%, #141414 98.62%) !important;
  position: sticky;
  top: 0;
  z-index: 100;

  & .wrap {
    display: flex;
    align-items: center;
    width: 100%;
  }

  & .backLink {
    display: flex;
    align-items: center;
    margin-right: 221px;
    & .icon {
      color: #FFFFFF;
      margin-right: 12px;
    }

    & .backLinkText {
      color: #ffffff
    }
  }

  .resolutionsContainer {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-right: 59px;

    & .resolutionButton {
      padding: 8px 16px;
      height: auto;
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      border-radius: 8px;
    }

    & .resolutionButtonActive {
      background-color: rgba(255, 255, 255, 0.1);
    }

    & .resolutionText {
      color: #fff;
      font-weight: 400;
      margin-left: 10px;
    }

    & .resolutionTextActive {
      font-weight: 700;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    column-gap: 16px;

    & .previewButton {
      height: 24px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      border: 1px solid #E1497E;

      & .previewButtonText {
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        color: #ffffff;
        margin-left: 8px;
      }
    }

    & .publishButton {
      display: flex;
      align-items: center;
      padding-inline: 26.5px;
      height: 22px;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
    }
  }
}
