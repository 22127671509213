.button_link {
  &_delete {
    &_btn {
      margin-top: 12px;
    }
    &_icon {
      fill: #5C5C6E;
      &:hover {
        fill: #DA1B5E;
      }
    }
  }

  &_url {
    & label {
      left: 48px
    }
  }
}

