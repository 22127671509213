.appTreeSelect {
  position: relative;

  .label {
    position: absolute;
    top: -10px;
    left: 16px;
    font-size: 12px;
    background-color: white;
    padding: 0 2px;
    z-index: 3;
    color: #9696A2;
  }

  .treeSelectComponent {
    min-width: 290px;
  }

  :global(.ant-select-selector) {
    border-color: #5C5C6E !important;
    box-shadow: none !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none !important;
  }

  :global(.ant-select-selection-item-content) {
    font-size: 12px;
    color: #191919;
    font-weight: bold;
  }
}

:global(.ant-tree-select) {
  height: 40px;
}

:global(.ant-select-tree-treenode) {
  align-items: center !important;
}

:global(.ant-select-tree-checkbox) {
  align-self: center !important;
}

:global(.ant-select-tree-treenode:hover) {
  outline: none !important;
  background-color: white !important;
  color: #DA1B5E !important;

  :global(.ant-select-tree-checkbox-inner) {
    border-color: #DA1B5E !important;
    transition-duration: 0s !important;
  }
}