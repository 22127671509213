.CellPopup
  animation-duration: 0.1s

  .ant-popover-content
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2)
    border-radius: 8px

  .ant-popover-inner
    background: linear-gradient(287.86deg, #282828 0%, #3F3F53 48.28%, #141414 98.62%)
    border-radius: 8px
    padding: 0

  .ant-popover-inner-content
    color: #fff
    min-width: unset
