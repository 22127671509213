.draggable_counter {
  &_zone_block {
    padding: 28px 0 0 0;

  }

  &_input {
    &_wrap {
      position: relative;
      border: 1px solid #CFCFD4;
      border-radius: 8px;
      padding: 15px 10px 0px 10px;
      margin-bottom: 36px;
    }
    &_drag_icon {
      position: absolute;
      top: 25px;
      left: -20px;
      cursor: pointer;
    }
  }
}