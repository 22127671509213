.handle {
  background-color: #323244;
  padding: 6px;
  color: #fff;
  border-radius: 8px 8px 0px 0px;
  max-width: 300px;
  display: flex;
  align-items: center;
  &_type {
    background-color: #404055;
    border-radius: 6px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 24px;
  }
}

.wrapper {
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
  width: 100%;
  padding: 20px 24px;
  border-radius: 0px 8px 8px 8px;
  background-color: #fff;
  &_image {
    position: relative;
  }
}

.info_icon_line {
  position: absolute;
  top: 58px;
  right: -9px;
}
