.draggable {
  &_zone_block {
    padding: 28px 0 0 0;
  }

  &_input {
    &_wrap {
      position: relative;
      z-index: 0;
    }
    &_drag_icon {
      position: absolute;
      top: 11px;
      left: -20px;
      cursor: pointer;
    }
  }
}