.variablesTable {
  position: absolute;
  right: 0;
  top: 0;
  height: calc(100vh - 51px);
  width: 500px;
  padding-top: 10px;
  background-color: white;
  box-shadow: 0px 4px 20px 0 rgba(0,0,0,.1);
  overflow-y: auto;
  z-index: 30;

  .variablesInfo {
    .section {
      padding: 20px 0;
      border-bottom: 1px solid #CFCFD4;
    }
    
    .header {
      color: #191919;
      font-weight: bold;
      font-size: 24px;
      padding-left: 25px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-right: 25px;
    }
  
    .addVariable {
      text-align: right;
  
      .action {
        color: #DA1B5E;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        background-color: transparent;
        border: none;
        padding-right: 25px;
      }
    }
  }

  .table {
    padding: 30px 8px;

    &.disabled {
      opacity: 0.6;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .column {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: default;
    }

    .header {
      font-size: 16px;
      color: #191919;
      font-weight: bold;
      padding: 0 8px;
      margin-bottom: 16px;
    }

    .tableRow {
      background-color: #F5F5F6;
      border-radius: 8px;
      margin-top: 8px;
      padding: 12px 8px;

      .defaultValue {
        max-width: 60px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .actionColumn {
        display: flex;
        flex-direction: row;

        .actions {
          display: flex;
          flex-direction: row;
          margin-left: auto;
          gap: 15px;

          svg {
            path {
              fill: #5C5C6E
            }

            &:hover path {
              fill: #DA1B5E
            }
          }
        }
      }
    }
  }
}