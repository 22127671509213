@import '../../../mixins';

.cardWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 16px;
  height: 100%;

  @include for-phone-only {
    width: 100%;
    margin-left: -16px;
  }

  & .cardInner {
    padding: 38px 40px 40px;
    border-radius: 4px;
    position: relative;
    height: 100%;
    // min-height: 293px;
    display: flex;
    background-color: #ffffff;
    cursor: pointer;

    @include for-tablet-portrait-up {
      width: 366px;
      margin-right: 16px;
    }

    @include for-desktop-up {
      width: 460px;
      margin-right: 16px;
    }
  }
}

.counterWrapper {
  margin-top: 24px;
  
  @include for-desktop-up {
    height: 110px;
  }
}
