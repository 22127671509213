@import '../../mixins';

.button {
  display: inline-flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  min-width: 155px;
  min-height: 48px;
  padding: 5px 12px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  word-break: break-all;
  border-radius: 24px;
  border: none;
  color: #FFFFFF;
  font-family: 'SimonKucher', serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  width: 155px;

  @include for-phone-only {
    width: 115px;
    min-width: 115px;
    min-height: 36px;
    line-height: 16px;
    height: 36px;
  }

  &:disabled {
    background: #f4bace;
    color: #fff;
  }
}