.detailTableComparison {
  .scoreOption {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .detailTableRadioGroup {
    margin-top: 16px;
    margin-bottom: 16px;
    flex-direction: row;
  
    & > label {
      margin-bottom: 0;
    }
  }

  .comparisonSeparator {
    background-color: #ECECEE;
    height: 1px;
    margin: 24px 0;

    &.topicSeparator {
      margin: 0;
    }
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .detailTableHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .comparisonTopicItem {
    padding: 24px 30px;
    .comparisonTopicHeader {
      position: relative;
      width: calc(100% + 60px);
      left: -30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
  
      & > div {
        flex: 1;
      }
    }
  }
}