.productRange {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border: 1px solid #5C5C6E;
  border-left: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0 16px;

  .inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #E3E3E6;
    border-radius: 8px;
    height: 25px;
    margin: 0 10px;
    overflow: hidden;

    .clearInput {
      border: 0;
      cursor: pointer;
      background-color: #E3E3E6;
      height: 100%;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      border: none;
      width: 40px;
      outline: none;
      font-size: 14px;
      margin-left: 5px;
      -webkit-appearance: none;
      appearance: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
    }
  }  
}