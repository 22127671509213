.bullet_select_icon {
  &_wrap {
    display: flex;
    align-items: center;
  }
  &_square {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #CFCFD4;
    border-radius: 8px;
    margin-right: 12px;

    &.small {
      height: 16px;
      width: 16px;
      border-radius: 5px;
      margin-right: 8px;
    }
  }
  &_name {
    max-width: 125px !important
  }
}
