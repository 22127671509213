.questions_savings {
  display: flex;
  width: 100%;
  overflow: hidden;
  &_drops_wrap {
    border-right: 1px solid #CFCFD4;
    width: 120px;
    padding: 20px 0;
    height: calc(100vh - 220px);
    overflow-y: auto;
  }
}

.products_savings {
  &_wrap {
    display: flex;
    flex-direction: column;
    width: calc(100% - 120px);
  }
  &_clear_btn {
    height: 56px;
    padding: 18px;
    border-bottom: 1px solid #CFCFD4;
    text-align: right;
  }
  &_description {
    margin-top: 20px;
    font-weight: 700;
    text-align: center;
  }
  &_text {
    display: block;
    padding: 0 16px;
    font-weight: 700;
  }
  &_title {
    display: block;
    width: 100%;
    padding: 10px 16px;
    border: 1px solid #CFCFD4;
    border-radius: 8px;
    color: #9696A2;
    &_wrap {
      padding: 16px;
      display: flex;
      align-items: center;
    }
  }
  &_block {
    padding-top: 16px ;
    overflow-y: auto;
    height: calc(100vh - 300px);
  }
  &_add_btn {
    height: 56px;
    padding: 18px;
    text-align: left;
  }
}
