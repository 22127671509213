@import '../../../mixins';

.formWrapper {
  display: flex;
  align-items: flex-start ;
  gap: 103px;
  margin-top: 45px;
  @include for-tablet-down{
    display: block;
    margin-top: 30px;
  }
}

.productListResultWrapper {
  position: absolute;
  z-index: 0;
  top: 0;
  width: 100%;
}