.product_scoring {
  &_box {
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CFCFD4;
    padding: 16px;
    &:last-child {
      border-bottom: none;
    }
  }
  &_icon {
    margin-right: 8px;
  }
  &_name {
    font-weight: 700;
    width: 220px;
    margin-right: 16px;
  }
}

